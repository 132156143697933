import { styled, Box } from "@mui/material";
import MyImage from "../../../assets/pic.jpeg";
import Resume from "../../../assets/resume.pdf";
import {
  CustomButton,
  SectionWrapper,
  TitleWrapper,
} from "../../../styles/sectionStyles";
import aboutMeInfo from "../../../data/AboutMe.json";
import { useEffect } from "react";

const AboutMeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  fontFamily: 'Sora',
  "& img": {
    width: 400,
    height: 250,
    [theme.breakpoints.down(1075)]: {
      width: 350,
      height: "auto",
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const DetailsWrapper = styled(Box)(({ theme }) => ({
  fontSize: 16,
  marginLeft: 15,
  fontFamily: 'Sora',
  "& .actions": {
    margin: "12px 0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 0 0 0",
  },
}));

export const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { summary } = aboutMeInfo.aboutMe;

  // Split the summary text into two paragraphs
  const paragraphs = summary.split('\n\n');

  return (
    <SectionWrapper>
      <TitleWrapper>About Me</TitleWrapper>
      <AboutMeContainer>
        <img src={MyImage} alt="main-img" />
        <DetailsWrapper>
          {/* Display each paragraph in a separate <div> */}
          {paragraphs.map((paragraph, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              {paragraph}
            </div>
          ))}

          <div className="actions">
            <CustomButton>
              <a href={Resume} rel="noreferrer" target="_blank">
                View Resume
              </a>
            </CustomButton>
          </div>
        </DetailsWrapper>
      </AboutMeContainer>
    </SectionWrapper>
  );
};
