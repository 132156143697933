import { styled, Box, Button, keyframes } from "@mui/material";
import { Constants } from "../../data/constants";
import { StyleConstants } from "../../styles/StyleConstants";

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const IntroductionContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  height: 84,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: 'Sora',
  backgroundColor: StyleConstants.HEADER,
  top: 0,
  fontSize: "2em",
  zIndex: 1,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.45em",
  },
}));

const BlinkingButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: "0.5em",
  backgroundColor: StyleConstants.BUTTON_COLOR || "#007bff",
  color: "#fff",
  fontWeight: 600,
  textTransform: "uppercase",
  animation: `${blinkAnimation} 1.5s infinite`,
  "&:hover": {
    backgroundColor: StyleConstants.BUTTON_HOVER_COLOR || "#0056b3",
  }
}));

export const Header = () => {
  return (
    <IntroductionContainer>
      {Constants.USERNAME}
      <a href="https://www.upwork.com/freelancers/mukash" target="_blank" rel="noopener noreferrer">
        <BlinkingButton variant="contained">Hire Me :)</BlinkingButton>
      </a>
    </IntroductionContainer>
  );
};
